<template>
    <div class="card card-custom gutter-b">
        <div class="card-header">
            <div class="card-title">
                Lộ trình học
            </div>
            <div class="card-title">
                <button
                    class="font-weight-bold font-size-3  btn btn-success"
                    @click="addnew"
                >Thêm mới
                </button>
            </div>
        </div>

        <div class="card-body">
            <div class="example mb-10">
                <div class="example-preview table-responsive">
                    <table class="table table-vertical-center table-hover table-bordered">
                        <thead>
                        <tr class="text-center">
                            <th>Mã</th>
                            <th>Tên</th>
                            <th>Folder name</th>
                            <th>Loại khóa học</th>
                            <th>Đảo</th>
                            <th style="width: 80px">Điểm tối thiểu giữa khóa</th>
                            <th style="width: 80px">Điểm tiêu chuẩn giữa khóa</th>
                            <th style="width: 80px">Điểm xuất sắc giữa khóa</th>
                            <th style="width: 80px">Điểm tối thiểu cuối khóa</th>
                            <th style="width: 80px">Điểm tiêu chuẩn cuối khóa</th>
                            <th style="width: 80px">Điểm xuất sắc cuối khóa</th>
                            <th>Hành động</th>
                        </tr>
                        </thead>
                        <tbody v-if="data.length > 0">
                        <tr v-for="(item, index) in data" :key="item.id">
                            <td>{{ item.id }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.folder_name }}</td>
                            <td>{{ convertCourseType(item.course_type) }}</td>
                            <td>{{ getIslandName(item.island_id) }}</td>
                            <td>{{ item.minimum_score_midterm }}</td>
                            <td>{{ item.medium_score_midterm }}</td>
                            <td>{{ item.maximum_score_midterm }}</td>
                            <td>{{ item.minimum_score_final_term }}</td>
                            <td>{{ item.medium_score_final_term }}</td>
                            <td>{{ item.maximum_score_final_term }}</td>
                            <td>
                                <!-- @click="actionApprove(index, item)" -->
                                <a title="Chỉnh sửa" href="javascript:"
                                   class="btn btn-sm btn-icon btn-outline-danger mx-1"
                                   @click="edit(index, item)"><i class="fas fa-pen-nib"></i></a>
                                <a title="Import lộ trình" href="javascript:"
                                   class="btn btn-sm btn-icon btn-outline-success mx-1" key=""
                                   @click='openDialogUpload("lo-trinh", item.id)'><i class="el-icon-upload"></i></a>
                                <a title="Import từ vựng" href="javascript:"
                                   class="btn btn-sm btn-icon btn-outline-info mx-1"
                                   @click='openDialogUpload("tu-vung", item.id)'> <i class="el-icon-top"></i></a>
                                <!-- <a title="Xem chi tiết"  href="javascript:"
                                  class="btn btn-sm btn-icon btn-outline-warning mx-1"> <i class="el-icon-view"></i></a> -->
                                <router-link
                                    class="font-weight-bold font-size-3 btn btn-success btn-sm"
                                    :to="{ name: 'schedule-course-detail', params: {id: item.id} , query :{course :item.name } }"
                                ><i class="el-icon-view"></i>
                                </router-link>
                            </td>
                        </tr>
                        </tbody>
                        <tbody v-else>
                        <tr>
                            <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <el-dialog :title="form_add ? 'Thêm mới lộ trình' : 'Chỉnh sửa lộ trình'"
                   :visible.sync="checkVisibleAddEditForm">
            <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                <form class="form" id="room_create" @submit.prevent="handleSubmit(form_add ? create : update )">
                    <div class="card card-custom">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Tên lộ trình
                                            <span class="text-danger">*</span></label>
                                        <ValidationProvider vid="name" name="Tên" rules="required"
                                                            v-slot="{ errors,classes }">
                                            <input type="text" ref="name" name="name" class="form-control"
                                                   v-model="form.name"
                                                   placeholder="Nhập Tên" :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{
                                                        errors[0]
                                                    }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Folder name
                                            <span class="text-danger">*</span></label>
                                        <ValidationProvider vid="folder_name" name="Folder name" rules="required"
                                                            v-slot="{ errors,classes }">
                                            <input type="text" ref="folder_name" name="folder_name" class="form-control"
                                                   v-model="form.folder_name"
                                                   placeholder="Nhập Folder name" :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="folder_name" data-validator="notEmpty"
                                                     class="fv-help-block">{{
                                                        errors[0]
                                                    }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Loại lộ trình
                                            <span class="text-danger">*</span></label>
                                        <ValidationProvider vid="course_type" name="Loại lộ trình" rules="required"
                                                            v-slot="{ errors,classes }">
                                            <el-select v-model="form.type" placeholder="Chọn loại lộ trình"
                                                       class="w-100" :class="classes">
                                                <el-option label="Thường" :value="0"></el-option>
                                                <el-option label="School review" :value="1"></el-option>
                                            </el-select>
                                            <div class="fv-plugins-message-container">
                                                <div data-field="course_type" data-validator="notEmpty"
                                                     class="fv-help-block">{{
                                                        errors[0]
                                                    }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="form.type != 1">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Loại khóa học
                                            <span class="text-danger">*</span></label>
                                        <ValidationProvider vid="course_type" name="Loại khóa học" rules="required"
                                                            v-slot="{ errors,classes }">
                                            <el-select v-model="form.course_type" placeholder="Chọn loại khóa học"
                                                       class="w-100" :class="classes">
                                                <el-option label="Giao tiếp" value="giao_tiep"></el-option>
                                                <el-option label="Ielts" value="ielts"></el-option>
                                                <el-option label="Pre-Ielts" value="pre_ielts"></el-option>
                                            </el-select>
                                            <div class="fv-plugins-message-container">
                                                <div data-field="course_type" data-validator="notEmpty"
                                                     class="fv-help-block">{{
                                                        errors[0]
                                                    }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="form.type != 1">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label> Đảo
                                            <span class="text-danger">*</span></label>
                                        <ValidationProvider vid="island_id" name="Đảo" rules="required"
                                                            v-slot="{ errors,classes }">
                                            <el-select v-model="form.island_id" placeholder="Chọn đảo" class="w-100"
                                                       :class="classes">
                                                <el-option v-for="(item) in island" :key="item.id" :label="item.name"
                                                           :value="item.id"></el-option>
                                            </el-select>
                                            <div class="fv-plugins-message-container">
                                                <div data-field="island_id" data-validator="notEmpty"
                                                     class="fv-help-block">{{
                                                        errors[0]
                                                    }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-5">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <label>Điểm tối thiểu giữa khóa
                                                <span class="text-danger">*</span></label>
                                            <ValidationProvider vid="minimum_score_midterm"
                                                                name="Điểm tối thiểu giữa khóa" rules="required"
                                                                v-slot="{ errors,classes }">
                                                <input type="text" ref="minimum_score_midterm"
                                                       name="minimum_score_midterm" class="form-control"
                                                       v-model="form.minimum_score_midterm"
                                                       placeholder="Nhập Điểm tối thiểu giữa khóa" :class="classes">
                                                <div class="fv-plugins-message-container">
                                                    <div data-field="minimum_score_midterm" data-validator="notEmpty"
                                                         class="fv-help-block">{{
                                                            errors[0]
                                                        }}
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-sm-4">
                                            <label>Điểm tiêu chuẩn giữa khóa
                                                <span class="text-danger">*</span></label>
                                            <ValidationProvider vid="medium_score_midterm"
                                                                name="Điểm tiêu chuẩn giữa khóa" rules="required"
                                                                v-slot="{ errors,classes }">
                                                <input type="text" ref="medium_score_midterm"
                                                       name="medium_score_midterm" class="form-control"
                                                       v-model="form.medium_score_midterm"
                                                       placeholder="Nhập Điểm tiêu chuẩn giữa khóa" :class="classes">
                                                <div class="fv-plugins-message-container">
                                                    <div data-field="medium_score_midterm" data-validator="notEmpty"
                                                         class="fv-help-block">{{
                                                            errors[0]
                                                        }}
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-sm-4">
                                            <label>Điểm xuất sắc giữa khóa
                                                <span class="text-danger">*</span></label>
                                            <ValidationProvider vid="maximum_score_midterm"
                                                                name="Điểm xuất sắc giữa khóa" rules="required"
                                                                v-slot="{ errors,classes }">
                                                <input type="text" ref="maximum_score_midterm"
                                                       name="maximum_score_midterm" class="form-control"
                                                       v-model="form.maximum_score_midterm"
                                                       placeholder="Nhập Điểm xuất sắc giữa khóa" :class="classes">
                                                <div class="fv-plugins-message-container">
                                                    <div data-field="maximum_score_midterm" data-validator="notEmpty"
                                                         class="fv-help-block">{{
                                                            errors[0]
                                                        }}
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <label>Điểm tối thiểu cuối khóa
                                                <span class="text-danger">*</span></label>
                                            <ValidationProvider vid="minimum_score_final_term"
                                                                name="Điểm tối thiểu cuối khóa" rules="required"
                                                                v-slot="{ errors,classes }">
                                                <input type="text" ref="minimum_score_final_term"
                                                       name="minimum_score_final_term" class="form-control"
                                                       v-model="form.minimum_score_final_term"
                                                       placeholder="Nhập Điểm tối thiểu cuối khóa" :class="classes">
                                                <div class="fv-plugins-message-container">
                                                    <div data-field="minimum_score_final_term" data-validator="notEmpty"
                                                         class="fv-help-block">{{
                                                            errors[0]
                                                        }}
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-sm-4">
                                            <label>Điểm tiêu chuẩn cuối khóa
                                                <span class="text-danger">*</span></label>
                                            <ValidationProvider vid="medium_score_final_term"
                                                                name="Điểm tiêu chuẩn cuối khóa" rules="required"
                                                                v-slot="{ errors,classes }">
                                                <input type="text" ref="medium_score_final_term"
                                                       name="medium_score_final_term" class="form-control"
                                                       v-model="form.medium_score_final_term"
                                                       placeholder="Nhập Điểm tiêu chuẩn cuối khóa" :class="classes">
                                                <div class="fv-plugins-message-container">
                                                    <div data-field="medium_score_final_term" data-validator="notEmpty"
                                                         class="fv-help-block">{{
                                                            errors[0]
                                                        }}
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-sm-4">
                                            <label>Điểm xuất sắc cuối khóa
                                                <span class="text-danger">*</span></label>
                                            <ValidationProvider vid="maximum_score_final_term"
                                                                name="Điểm xuất sắc cuối khóa" rules="required"
                                                                v-slot="{ errors,classes }">
                                                <input type="text" ref="maximum_score_final_term"
                                                       name="maximum_score_final_term" class="form-control"
                                                       v-model="form.maximum_score_final_term"
                                                       placeholder="Điểm xuất sắc cuối khóa" :class="classes">
                                                <div class="fv-plugins-message-container">
                                                    <div data-field="maximum_score_final_term" data-validator="notEmpty"
                                                         class="fv-help-block">{{
                                                            errors[0]
                                                        }}
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-header">
                            <div class="card-title align-items-start flex-column  float-right">
                                <button
                                    type="submit"
                                    class="btn btn-success mr-2 float-right"
                                >
                                    {{ form_add ? 'Tạo mới' : "Chỉnh sửa" }}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
            <div slot="footer" class="dialog-footer">
            </div>
        </el-dialog>


        <el-dialog :title="(type_upload=='lo-trinh') ? 'Import Lộ trình' : 'Import từ vựng'"
                   :visible.sync="dialogUploadFile">
            <div class="example-btn d-flex justify-content">
                <!-- <el-upload
                  class="upload-demo"
                  ref="upload"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :auto-upload="false"
                  :before-upload="handleOnBeforeUpload">
                  <el-button slot="trigger" size="small" type="primary">Chọn file </el-button>

                </el-upload> -->
                <input type="file" @change="handleTestFileUploads( $event )" class="w-full"/>
                <el-button style="margin-left: 10px;" size="small" type="success" @click="importData">Import</el-button>
            </div>
            <div class="example-btn d-flex justify-content">
                <el-button @click="dialogUploadFile = false" class="float-right m-4">Cancel</el-button>
                <div class="clear-both"></div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
const ISLAND = [
    {
        id: 1,
        name: 'Đảo thông thái Athena'
    },
    {
        id: 2,
        name: 'Rừng nhiệt đới Amazonica'
    },
    {
        id: 3,
        name: 'Vương quốc sa mạc Isukha'
    },
    {
        id: 4,
        name: 'Thành phố cực Bắc Arcint'
    },
    {
        id: 5,
        name: 'Thành phố Edo'
    },
    {
        id: 6,
        name: 'Đảo Flyland'
    },
    {
        id: 7,
        name: 'Đại dương Moa'
    },
]

import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import Multiselect from 'vue-multiselect';
import {
    CREATE_SCHEDULE_COURSE,
    IMPORT_LO_TRINH,
    IMPORT_TU_VUNG,
    LIST_SCHEDULE_COURSE,
    UPDATE_SCHEDULE_COURSE
} from '../../../core/services/store/course/classes.module';

const _ = require('lodash');

export default {
    name: "Off-Lesson",

    components: {
        Multiselect
    },
    data() {
        return {
            island: ISLAND,
            page: 1,
            data: [],
            files: [],
            checkVisibleAddEditForm: false,
            form: {
                name: '',
                type: ''
            },
            form_add: false,
            dialogUploadFile: false,
            type_upload: '',
            schedule_id: 0,
            loading: false
        }
    },

    mounted() {
        this.getListScheduleCourse();
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Quản lý lộ trình", route: 'schedule-course-list'},
            {title: "Quản lý lộ trình"}
        ]);
    },

    methods: {
        getIslandName(id) {
            let index = _.findIndex(ISLAND, {'id': id});
            if (index == -1)
                return '';
            return ISLAND[index].name;
        },
        getListScheduleCourse() {
            this.$store.dispatch(LIST_SCHEDULE_COURSE, {
                page: this.page
            }).then((res) => {
                this.data = res.data
            });
        },
        actionApprove(index, item) {
            this.dialogFormVisible = true;
            this.item = item;
            this.index = index;
        },
        convertCourseType(type) {
            let value = 'Chưa cập nhật';
            switch (type) {
                case 'giao_tiep':
                    value = 'Giao tiếp';
                    break;
                case 'pre_ielts':
                    value = 'Pre Ielts';
                    break;
                case 'ielts':
                    value = 'Ielts';
                    break;
                default:
                  value = 'School Review';
                  break;
                    break;
            }

            return value;
        },

        edit(index, item) {
            console.log(item, index);
            this.form = item
            this.checkVisibleAddEditForm = true;
        },

        addnew() {
            this.form = {}
            this.checkVisibleAddEditForm = true;
            this.form_add = true
        },
        create() {
            try {
                this.$store.dispatch(CREATE_SCHEDULE_COURSE, this.form).then((res) => {
                    this.$notify('Thêm mới lộ trình thành công', {
                        title: 'Thành công mới',
                        variant: 'success',
                        solid: true
                    });
                    this.getListScheduleCourse();
                    this.checkVisibleAddEditForm = false;
                });
            } catch (error) {
                console.log("error", error)
            }
        },

        update() {
            try {
                this.$store.dispatch(UPDATE_SCHEDULE_COURSE, this.form).then((res) => {
                    this.$notify('Sửa lộ trình thành công', {
                        title: 'Thành công sửa',
                        variant: 'success',
                        solid: true
                    });
                    this.getListScheduleCourse();
                    this.checkVisibleAddEditForm = false;
                });
            } catch (error) {
                console.log("error", error)
            }
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        searchData() {
            this.getListRequestOffClass()
        },
        clickCallback(obj) {
            this.page = obj
            this.getListRequestOffClass()
        },
        openDialogUpload(type_upload, id) {
            this.dialogUploadFile = true,
                this.type_upload = type_upload,
                this.schedule_id = id
        },

        handleTestFileUploads(event) {
            this.files = event.target.files;
        },

        importData() {
            this.loading = true;
            let formData = new FormData();
            formData.append('file', this.files[0]);
            formData.append('schedule_id', this.schedule_id);
            if (this.type_upload === 'lo-trinh') {
                this.$store.dispatch(IMPORT_LO_TRINH, formData).then((res) => {
                    this.dialogUploadFile = false;
                    this.$notify({
                        title: 'Thành công',
                        type: 'success',
                        message: 'Import thành công'
                    });
                }).catch((err) => {
                    this.$notify({
                        title: 'Thành công',
                        type: 'error',
                        message: err.data.message,
                    });
                }).finally(() => {
                    this.loading = false
                })
            } else if (this.type_upload === 'tu-vung')
                this.$store.dispatch(IMPORT_TU_VUNG, formData).then((res) => {
                    this.dialogUploadFile = false;
                    this.$notify({
                        title: 'Thành công',
                        type: 'success',
                        message: 'Import thành công'
                    });
                }).catch((err) => {
                    this.$notify({
                        title: 'Thành công',
                        type: 'error',
                        message: err.data.message,
                    });
                }).finally(() => {
                    this.loading = false
                })
        },
        changeTypeCourse() {

        }
    }
}

</script>
